import React from 'react';
import {inject, observer} from 'mobx-react';
import {PersonIcon} from '../../../styles/icons/index';
import _ from 'lodash';

const UserCard = ({userStore}) => {
    if (_.isEmpty(userStore.userCard)) {
        return null;
    }

    return (
        <div className="entity">
            <div className="entity-icon">
                <PersonIcon/>
            </div>
            <div className="entity-info">
                <div className="entity-name">{`${userStore.userCard.givenName} ${userStore.userCard.surname}`}</div>
                <div className="entity-username">{userStore.userCard.username}</div>
            </div>
        </div>
    );
};

export default inject(
    'userStore'
)(observer(UserCard));

