import React from 'react';
import {
    EncCheckboxField,
    EncForm,
    EncInputField,
    EncSelectField,
    withEncFormHandler
} from '../../../../../../../../../components/hocs/form-validation/index';

const FACTSET_CONNECTION_ALLOWED = 'Username-Password-Authentication';

let EditAccountUser = (props) => {
    const {formProperties, fieldProperties, initialValues} = props;
    const connInfo = props.connectionInfo.find(conn => conn.name === fieldProperties.values.connection) || {};

    if (connInfo.name !== FACTSET_CONNECTION_ALLOWED && fieldProperties.values.factsetAccessAllowed) {
        props.setFieldValue('factsetAccessAllowed', false);
    }

    return (
        <EncForm formProperties={formProperties} fieldProperties={fieldProperties}>
            <EncInputField
                id="givenName"
                label="user:user.given.name"
            />
            <EncInputField
                id="surname"
                label="user:user.surname"
            />
            <EncInputField
                id="Partner_User_ID__c"
                label={"user:user.username"}
                disabled={true}
                validator={{
                    type: 'string',
                    rules: ['required', 'email']
                }}
            />
            <EncInputField
                id="password"
                type="password"
                label="user:user.password"
                validator={{
                    type: 'string',
                    passwordRequired: false,
                    passwordMinLength: connInfo.passwordMinLength,
                    passwordPolicy: connInfo.passwordPolicy,
                    requiredIf: initialValues.connection !== fieldProperties.values.connection,
                    requiredIfMessage: 'user:user.connection.is.being.switched',
                    rules: ['requiredIf', 'auth0Password']
                }}
            />
            <EncSelectField
                id="connection"
                label="user:user.connection"
                options={props.connectionOptions}
                validator={{
                    type: 'string',
                    rules: ['required']
                }}
            />
            <EncCheckboxField
                id="active"
                label="user:user.active"
                disabled={props.disableActive}
            />
            <EncCheckboxField
                id="accountAdmin"
                label="user:user.account.admin"
            />
            <EncCheckboxField
                id="uiAccessAllowed"
                label="user:user.ui.access"
            />
            <EncCheckboxField
                id="apiAccessAllowed"
                label="user:user.api.access"
            />
            {connInfo.name !== FACTSET_CONNECTION_ALLOWED
                ? null
                : <EncCheckboxField
                    id="factsetAccessAllowed"
                    label="user:user.factset.access"
                />
            }
        </EncForm>
    );
};

const FORM_VALIDATION_OPTIONS = {
    header: 'user:user.edit.user',
    successMessage: 'user:user.user.successfully.updated',
    validateOnChange: false,
    validateOnBlur: true,
    closeOnPristine: true,
    confirmAlertMsg: (initialValues, submitValues) => {
        let messages = [];

        if (initialValues.connection !== submitValues.connection) {
            messages.push('user:user.user.switching.connections');
            messages.push({
                translation: 'user:user.user.switching.connections.message',
                values: {environment: initialValues.connection}
            });
        }

        if (initialValues.active === true && submitValues.active === false) {
            messages.push('user:user.user.being.made.inactive');
            messages.push('user:user.user.inactive.confirm.message');
        } else if (initialValues.active === false && submitValues.active === true) {
            messages.push('user:user.user.being.made.active');
            messages.push('user:user.user.active.confirm.message');
        }

        return messages.length === 0 ? undefined
            : {
                header: 'user:user.confirm.edit.user',
                messages: messages
            };
    }
};

export default withEncFormHandler(FORM_VALIDATION_OPTIONS)(EditAccountUser);