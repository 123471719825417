import React from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import {Button} from 'semantic-ui-react';
import {useParams} from 'react-router-dom';
import {useNavigate} from 'react-router';

let ActiveAccountUsersTableButtons = (props) => {
    const {
        t, activeAccountUsersUIStore, connectionInformationStore
    } = props;

    const params = useParams();
    const navigate =  useNavigate();

    function navigateToUserOverview() {
        let userOverviewPath = `/accounts/${params.accountId}/users/${activeAccountUsersUIStore.singleSelectedUser.id}`;
        navigate(userOverviewPath);
    }

    return (
        <div>
            {
                activeAccountUsersUIStore.isSingleSelectedUser &&
                <Button onClick={navigateToUserOverview}>
                    {t('common:common.open')}
                </Button>
            }
            {
                activeAccountUsersUIStore.isSelectedUserEditable &&
                <Button onClick={activeAccountUsersUIStore.openEditUserModal} disabled={connectionInformationStore.isLoading}>
                    {t('common:common.edit')}
                </Button>
            }
            {
                activeAccountUsersUIStore.isSelectedUsersActivatable &&
                <Button onClick={activeAccountUsersUIStore.openActivateConfirmModal}>
                    {t('user.activate')}
                </Button>
            }
            {
                activeAccountUsersUIStore.isSelectedUsersDeactivatable &&
                <Button onClick={activeAccountUsersUIStore.openDeactivateConfirmModal}>
                    {t('user.deactivate')}
                </Button>
            }
            {
                activeAccountUsersUIStore.isSelectedUsersArchivable &&
                <Button onClick={activeAccountUsersUIStore.openArchiveConfirmModal}>
                    {t('common:common.archive')}
                </Button>
            }
        </div>
    );
};

export default inject(
    'activeAccountUsersUIStore',
    'connectionInformationStore'
)(withTranslation(
    'user'
)(observer(ActiveAccountUsersTableButtons)));