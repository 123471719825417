import React, {useEffect, useRef, useContext, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import EncDateRangePicker from '../../../../../../components/date-range-picker/index';
import { Button } from 'semantic-ui-react';
import NoResultsMessage from '../../../../../../components/table-select/NoResultsMessage';
import columnConfig from './user-transactions.column-config';
import PageableTableSelect from '../../../../../../components/table-select/pageable/index';
import { MobXProviderContext } from 'mobx-react';
import { useParams } from 'react-router-dom';

const UserTransactions = observer(() => {
    const { t } = useTranslation();
    const { userId } = useParams();
    const { transactionStore, userTransactionsUIStore, userStore } = useContext(MobXProviderContext);
    const tableSelectRef = useRef(null);
    const [tableSelectFilter, setTableSelectFilter] = useState('');

    const setTableSelectRef = (node) => {
        tableSelectRef.current = node;
    };

    useEffect(() => {
        userStore.retrieveUser(userId);

        return () => {
            userTransactionsUIStore.reset();
        };
    }, [userId, userStore, userTransactionsUIStore]);

    const exportToCSV = () => {
        transactionStore.exportToCsv(
            userTransactionsUIStore.dateRange,
            tableSelectFilter,
            undefined,
            { contactId: userId, contactName: userStore.user.Partner_User_ID__c }
        );
    };

    const handleTextFilterChange = filter => {
        if (tableSelectRef.current) {
            setTableSelectFilter(filter);
        }
    };

    const getPageableTransactions = (...params) => {
        transactionStore.getPageableTransactions(userTransactionsUIStore.dateRange, undefined, userId)(...params);
    };

    const renderAdditionalTableFilters = () => (
        <EncDateRangePicker
            startDate={userTransactionsUIStore.dateRange.startDate}
            endDate={userTransactionsUIStore.dateRange.endDate}
            handleRange={dateRange => {
                userTransactionsUIStore.setDateRange(dateRange);
                if (tableSelectRef.current) {
                    tableSelectRef.current.refreshTable();
                }
            }}
        />
    );

    const renderNoResultsMessage = () => (
        <NoResultsMessage
            startDate={userTransactionsUIStore.dateRange.startDate}
            endDate={userTransactionsUIStore.dateRange.endDate}/>
    );

    return (
        <div className="content sticky-scroll">
            <div className="section__full--width--padding mt-4">
                <Button className="button__primary" onClick={exportToCSV}>
                    {t('common:common.export.to.csv')}
                </Button>
            </div>

            <div className="sticky-scroll">
                <PageableTableSelect
                    multiSelect={false}
                    selectable={false}
                    columnConfig={columnConfig}
                    fetchPageableData={getPageableTransactions}
                    rowId="id"
                    isSticky={true}
                    stickyTopOffset={55}
                    stickyTopBarOffset={0}
                    dataName={t('transactions:transactions.data.name')}
                    noDataText={renderNoResultsMessage()}
                    defaultColumnSorted="endTransaction"
                    tableSelectRef={setTableSelectRef}
                    handleTextFilterChange={handleTextFilterChange}
                    additionalFilteringComponents={renderAdditionalTableFilters()}/>
            </div>
        </div>
    );
});

export default UserTransactions;
