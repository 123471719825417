import React from 'react';
import {
    EncCheckboxField,
    EncForm,
    EncInputField,
    EncSelectField,
    withEncFormHandler
} from '../../../../../../../../../components/hocs/form-validation/index';

const FACTSET_CONNECTION_ALLOWED = 'Username-Password-Authentication';

let AddAccountUser = (props) => {
    const {formProperties, fieldProperties} = props;
    const connInfo = props.connectionInfo ? props.connectionInfo.find(conn => conn.name === fieldProperties.values.connection) : {};

    if (connInfo.name !== FACTSET_CONNECTION_ALLOWED && fieldProperties.values.factsetAccessAllowed) {
        props.setFieldValue('factsetAccessAllowed', false);
    }

    return (
        <EncForm formProperties={formProperties} fieldProperties={fieldProperties}>
            <EncInputField
                id="givenName"
                label="user:user.given.name"
            />
            <EncInputField
                id="surname"
                label="user:user.surname"
            />
            <EncInputField
                id="Partner_User_ID__c"
                label={"user:user.username"}
                validator={{
                    type: 'string',
                    rules: ['required', 'email']
                }}
            />
            <EncInputField
                id="password"
                type="password"
                label="user:user.password"
                validator={{
                    type: 'string',
                    passwordMinLength: connInfo.passwordMinLength,
                    passwordPolicy: connInfo.passwordPolicy,
                    rules: ['required', 'auth0Password']
                }}
            />
            <EncSelectField
                id="connection"
                label="user:user.connection"
                options={props.connectionOptions}
                validator={{
                    type: 'string',
                    rules: ['required']
                }}
            />
            <EncCheckboxField
                id="active"
                label="user:user.active"
                disabled={props.disableActive}
            />
            <EncCheckboxField
                id="accountAdmin"
                label="user:user.account.admin"
            />
            <EncCheckboxField
                id="uiAccessAllowed"
                label="user:user.ui.access"
            />
            <EncCheckboxField
                id="apiAccessAllowed"
                label="user:user.api.access"
            />
            {connInfo.name !== FACTSET_CONNECTION_ALLOWED
                ? null
                : <EncCheckboxField
                    id="factsetAccessAllowed"
                    label="user:user.factset.access"
                />
            }
        </EncForm>
    );
};

const FORM_VALIDATION_OPTIONS = {
    header: 'user:user.add.user',
    successMessage: 'user:user.user.successfully.created',
    validateOnChange: true,
    validateOnBlur: true,
    confirmAlertMsg: (initialValues, submitValues) => {
        if (submitValues.active === false) {
            return {
                header: 'user:user.confirm.add.user',
                messages: 'user:user.user.inactive.confirm.message'
            }
        }

        return undefined;
    }
};

export default withEncFormHandler(FORM_VALIDATION_OPTIONS)(AddAccountUser);